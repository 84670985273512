<template>
  <div class="grid grid-cols-1 bg-white">
    <h1>Einstellungen</h1>
    <form @submit.prevent="saveSettings">
      <label>
        Aluminium Preis:
        <input type="number" step=".01" v-model="settings.aluminiumPrice" />
      </label>

      <button type="submit">Einstellungen speichern</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Settings",

  data() {
    return {
      settings: {
        aluminiumPrice: 0
      }
    };
  },

  created() {
    this.readSettings();
  },

  methods: {
    async readSettings() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/settings/read`)
        .then(res => {
          this.settings = res.data.settings;
          localStorage.setItem("settings", JSON.stringify(this.settings));
          this.$store.commit("updateSettings", this.settings);
        })
        .catch(err => {
          console.error(err);
        });
    },
    async saveSettings() {
      await axios
        .post(`${process.env.VUE_APP_API_URL}/settings/save`, {
          aluminium_price: this.settings.aluminiumPrice
        })
        .then(res => {
          console.log(res);
          localStorage.setItem("settings", JSON.stringify(this.settings));
          this.$store.commit("updateSettings", this.settings);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>
